import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControlLabel, Grid, IconButton, ImageList, ImageListItem, Input, InputAdornment, MenuItem, Stack, TextField, Typography, useMediaQuery, useTheme } from '@mui/material';
import { ProductData, ProductForm, ProductInput } from '../../../../models/product';
import EuroSymbolIcon from '@mui/icons-material/EuroSymbol';
import ImageIcon from '@mui/icons-material/Image';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import { useAdminContext } from '../AdminContext';
import { Add, Close, Edit } from '@mui/icons-material';

interface ProductModalProps {
    type: "create" | "edit"
    open: boolean;
    onClose: () => void;
    onSave: (tpe: "create" | "edit", product: ProductForm) => void;
    product?: ProductData;
}

export interface ProductModalRef {
    resetFormData: () => void;
}

const fieldsValidations = {
    name: {
        minLength: 10,
        maxLength: 255
    },
    price: {
        min: 1
    },
    short_description: {
        minLength: 25,
        maxLength: 500
    }
}

const ProductModal = forwardRef<ProductModalRef, ProductModalProps>(({ type, open, onClose, onSave, product }, ref) => {

    const theme = useTheme();

    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const { categoriesState } = useAdminContext();


    const [formData, setFormData] = useState<ProductForm>(() => {
        return product !== undefined ? {
            id: product.id,
            name: product.name,
            short_description: product.short_description,
            main_description: product.main_description,
            price: product.price,
            sold: product.sold,
            category_id: product.category.id,
            main_image: product.main_image,
            instagram_link: product.instagram_link,
            secondary_images: product.secondary_images,
            secondary_images_files: [],
            main_image_file: null
        } : {
            id: '',
            name: '',
            short_description: '',
            main_description: '',
            price: 1,
            sold: false,
            category_id: null,
            main_image: null,
            secondary_images: [],
            secondary_images_files: [],
            main_image_file: null
        };
    });

    // Create a single state variable for errors and pristine states
    const [formState, setFormState] = useState({
        valid: false,
        errors: {
            name: false,
            price: false,
            category: false,
            short_description: false,
            main_image: false
        },
        pristine: {
            name: true,
            price: true,
            category: true,
            short_description: true
        },
    });

    const [mainImageDisplay, setMainImageDisplay] = useState<string>("")
    const [secondaryImagesDisplay, setSecondaryImagesDisplay] = useState<string[]>([])
    const secondaryImagesMap = useRef<Map<number, number>>(new Map());


    useEffect(() => {

        if (product !== undefined) {
            const productData = {
                id: product.id,
                name: product.name,
                short_description: product.short_description,
                main_description: product.main_description,
                price: product.price,
                sold: product.sold,
                category_id: product.category.id,
                main_image: product.main_image,
                instagram_link: product.instagram_link,
                secondary_images: product.secondary_images,
                secondary_images_files: [],
                main_image_file: null
            }
            setFormData({ ...productData })

            setMainImageDisplay(product.main_image.src)
            const imagesDisplay = product.secondary_images.map((image) => image.data!)
            setSecondaryImagesDisplay(imagesDisplay)
            validateFields(productData)
        }
    }, [product])

    useEffect(() => {
        validateFields(formData);
        console.log(formData)
    }, [formData]);

    const validateFields = (formData: ProductForm) => {
        const currentFormData = { ...formData };
        const errors = {
            name: !currentFormData.name || currentFormData.name.length < fieldsValidations.name.minLength || currentFormData.name.length > fieldsValidations.name.maxLength,
            price: !currentFormData.price,
            category: !currentFormData.category_id,
            short_description: !currentFormData.short_description || currentFormData.short_description.length < fieldsValidations.short_description.minLength || currentFormData.short_description.length > fieldsValidations.short_description.maxLength,
            main_image: currentFormData.main_image_file === null && currentFormData.main_image == null
        };
        // Check if any of the fields have errors
        const isValid = Object.values(errors).every(error => error === false);

        setFormState(prevState => ({
            ...prevState,
            valid: isValid, // Explicitly update isValid
            errors
        }));
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value, type, checked } = e.target;
        let updatedData: ProductForm;

        // Check the type of input and handle accordingly
        if (type === 'checkbox') {
            updatedData = {
                ...formData,
                [name]: checked
            };
        } else {
            updatedData = {
                ...formData,
                [name]: value
            }
        }

        // Update the state with the updated data
        setFormData(updatedData as ProductForm);

        // Update pristine state only if the field name exists
        if (formState.pristine.hasOwnProperty(name)) {
            setFormState(prevState => ({
                ...prevState,
                pristine: {
                    ...prevState.pristine,
                    [name]: false,
                },
            }));
        }

        validateFields(updatedData);
    };

    const handleSubmit = () => {
        onSave(type, formData);
        onClose();
    };

    const resetFormData = () => {
        setFormData({
            id: '',
            name: '',
            short_description: '',
            main_description: '',
            price: 1,
            sold: false,
            category_id: null,
            main_image: null,
            secondary_images: [],
            secondary_images_files: [],
            main_image_file: null
        });

        setMainImageDisplay("")
        setSecondaryImagesDisplay([])
        secondaryImagesMap.current = new Map()
    }

    useImperativeHandle(ref, () => ({
        resetFormData,
    }));

    const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const files = e.target.files;
        if (files && files.length > 0) {
            const file = files[0];
            const reader = new FileReader();

            reader.onloadend = () => {
                const newFormData = {
                    ...formData,
                    main_image_file: file
                };

                setFormData(newFormData);
                setMainImageDisplay(reader.result as string);
                validateFields(newFormData);
            };

            reader.readAsDataURL(file);
        }
    };

    const handleSecondaryImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const files = e.target.files;
        if (files && files.length > 0) {
            const file = files[0];
            const reader = new FileReader();
            reader.onloadend = () => {
                setFormData(prevData => {
                    console.log(reader.result)
                    const newSecondaryImagesSrc = [...secondaryImagesDisplay, reader.result as string];
                    setSecondaryImagesDisplay(newSecondaryImagesSrc);
                    const newSecondaryImagesFiles = [...prevData.secondary_images_files, file];
                    secondaryImagesMap.current.set(newSecondaryImagesSrc.length - 1, newSecondaryImagesFiles.length - 1);
                    return {
                        ...prevData,
                        secondary_images_files: newSecondaryImagesFiles
                    };
                });
            };
            reader.readAsDataURL(file);
        }
    };

    function handleRemoveMainImage(): void {

        setFormData(prevState => ({
            ...prevState,
            main_image_src: '',
            main_image: null,
            main_image_file: null
        }));

        setMainImageDisplay('');
    }


    function handleRemoveSecondaryImage(imageIdx: number): void {
        // Create copies of the arrays and maps to avoid mutating the original state
        const updatedImages = [...formData.secondary_images];
        const updatedImageFiles = [...formData.secondary_images_files];
        const updatedImagesDisplay = [...secondaryImagesDisplay];
        const updatedMap = new Map<number, number>(secondaryImagesMap.current);
        // Remove the item at the specified index from the arrays if it exists
        if (imageIdx < updatedImages.length) {
            updatedImages.splice(imageIdx, 1);
        }

        updatedImagesDisplay.splice(imageIdx, 1);

        // Get the index of the file to remove from the files array using the map
        const fileIndex = secondaryImagesMap.current.get(imageIdx)!;
        updatedImageFiles.splice(fileIndex, 1);

        // Remove the entry from the map
        updatedMap.delete(imageIdx);
        // Update the state with the updated arrays
        setFormData(prevState => ({
            ...prevState,
            secondary_images: updatedImages,
            secondary_images_files: updatedImageFiles
        }));

        // Update the secondaryImagesMap and secondaryImagesDisplay
        secondaryImagesMap.current = updatedMap;
        setSecondaryImagesDisplay([...updatedImagesDisplay]);
    }

    return (

        <Dialog open={open} onClose={onClose} fullWidth maxWidth="lg">
            <DialogTitle sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>

                {product ?
                    <Stack direction="row" spacing={1} alignItems={"center"}>
                        <Edit fontSize='large' />
                        <Typography variant='h6'>
                            Editar Producto
                        </Typography>
                    </Stack> :
                    <Stack direction="row" spacing={1} alignItems={"center"}>
                        <Add fontSize='large' />
                        <Typography variant='h6'>
                            Crear nuevo producto
                        </Typography>
                    </Stack>}
                <IconButton onClick={onClose} aria-label="close">
                    <Close />
                </IconButton>

            </DialogTitle>
            <Divider />
            <DialogContent>
                <Stack spacing={2} sx={{ pr: 4 }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                            <TextField required name="name" label="Nombre"
                                error={formState.errors.name && !formState.pristine.name}
                                helperText={(formState.errors.name && !formState.pristine.name) ? `Campo requerido, min longitud: ${fieldsValidations.name.minLength} / max longitud: ${fieldsValidations.name.maxLength}  carácteres` : ""}
                                value={formData.name}
                                onChange={handleChange}
                                fullWidth />
                        </Grid>
                        <Grid item xs={6} md={3}>
                            <TextField required
                                name="price"
                                label="Precio"
                                type="number"
                                error={formState.errors.price && !formState.pristine.price}
                                helperText={(formState.errors.price && !formState.pristine.price) ? `Campo requerido, mínimo valor: ${fieldsValidations.price.min}` : ""} value={formData.price}
                                onChange={handleChange}
                                InputProps={{ // Add InputProps to include the EuroSymbolIcon
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <EuroSymbolIcon />
                                        </InputAdornment>
                                    ),
                                }} fullWidth />
                        </Grid>
                        <Grid item xs={6} md={3}>
                            <TextField
                                name="category_id"
                                required
                                select
                                label="Categoría"
                                error={formState.errors.category && !formState.pristine.category}
                                helperText={(formState.errors.category && !formState.pristine.category) ? "Campo requerido" : ""}
                                value={formData.category_id}
                                onChange={handleChange}
                                fullWidth
                            >
                                {categoriesState.categories.map((option) => (
                                    <MenuItem key={option.id} value={option.id}>
                                        {option.name}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={formData.sold}
                                        onChange={handleChange}
                                        name="sold"
                                        color="primary"
                                    />
                                }
                                label="Vendido"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                required
                                name="short_description"
                                label="Descripción resumida"
                                error={formState.errors.short_description && !formState.pristine.short_description}
                                value={formData.short_description}
                                onChange={handleChange}
                                helperText={(formState.errors.short_description && !formState.pristine.short_description) ? `Campo requerido, min longitud: ${fieldsValidations.short_description.minLength} / max logitud: ${fieldsValidations.short_description.maxLength} carácteres` : ""}
                                multiline
                                rows={4} // Adjust the number of rows as needed
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                name="main_description"
                                label="Descripción"
                                value={formData.main_description}
                                onChange={handleChange}
                                multiline
                                rows={8} // Adjust the number of rows as needed
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                name="instagram_link"
                                label="Enlace a Instagram"
                                value={formData.instagram_link}
                                onChange={handleChange}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} md={6} >
                            <Stack spacing={2}>
                                <Typography variant='h6'>Imagen Principal *</Typography>
                                <Input
                                    required
                                    type="file"
                                    onChange={handleImageChange}
                                    style={{ display: 'none' }}
                                    id="upload-main-image-button"
                                />
                                <label htmlFor="upload-main-image-button">
                                    <Button
                                        variant="outlined"
                                        component="span"
                                        startIcon={<ImageIcon />}
                                    >
                                        Subir Imagen
                                    </Button>
                                </label>
                                {mainImageDisplay && ( // Display preview if main_image_src is set

                                    <div style={{ position: 'relative' }}>
                                        <img src={formData.main_image?.data ? `${formData.main_image.data}` : mainImageDisplay} alt="Main Image Preview" style={{ maxWidth: '100%', height: 'auto' }} />
                                        <IconButton
                                            style={{ position: 'absolute', top: "10px", right: isMobile ? 0 : '15px', background: 'transparent', border: 'none', cursor: 'pointer' }}
                                            onClick={() => { handleRemoveMainImage() }}
                                        >
                                            <CancelRoundedIcon sx={{ color: theme.palette.error.dark }} />
                                        </IconButton>
                                    </div>
                                )}
                            </Stack>
                        </Grid>
                        <Grid item xs={12} md={6} >
                            <Stack spacing={2}>
                                <Typography variant='h6'>Imágenes Secundarias</Typography>
                                <Input
                                    type="file"
                                    onChange={handleSecondaryImageChange}
                                    style={{ display: 'none' }}
                                    id="upload-secondary-images-button"
                                />
                                <label htmlFor="upload-secondary-images-button">
                                    <Button
                                        variant="outlined"
                                        component="span"
                                        startIcon={<ImageIcon />}
                                        disabled={formData.secondary_images.length >= 4}
                                    >
                                        Subir Imagen
                                    </Button>
                                </label>
                                {secondaryImagesDisplay.length > 0 &&
                                    <ImageList cols={2}>
                                        {secondaryImagesDisplay.map((item, idx) => (
                                            <ImageListItem key={item}>
                                                <div style={{ position: 'relative' }}>
                                                    <img src={item} alt="Main Image Preview" style={{ maxWidth: '100%', height: isMobile ? 124 : 248 }} />
                                                    <IconButton
                                                        style={{ position: 'absolute', top: "10px", right: isMobile ? 0 : '15px', background: 'transparent', border: 'none', cursor: 'pointer' }}
                                                        onClick={() => handleRemoveSecondaryImage(idx)}
                                                    >
                                                        <CancelRoundedIcon sx={{ color: theme.palette.error.dark }} />
                                                    </IconButton>
                                                </div>
                                            </ImageListItem>
                                        ))}
                                    </ImageList>
                                }
                            </Stack>
                        </Grid>

                    </Grid>
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button variant='outlined' onClick={onClose}><Typography>Cancelar</Typography></Button>
                <Button variant='outlined' disabled={!formState.valid} onClick={handleSubmit}><Typography>{product ? 'Guardar Cambios' : 'Añadir Producto'}</Typography></Button>
            </DialogActions>
        </Dialog>


    );
});

export default ProductModal;
