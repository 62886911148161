import * as React from 'react';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import { IconButton, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import { Edit, Delete, Visibility, Star, StarBorder } from '@mui/icons-material';
import ProductModal from './ProductEditModal';
import { ProductData, ProductForm } from '../../../../models/product';
import ProductViewModal from './ProductViewModal';
import { useAdminContext } from '../AdminContext';

interface AdminProductImageListProps {
  products: ProductData[];
  onSave: (type: "create" | "edit", product: ProductForm) => void;
  onDelete: (productData: ProductData) => void;
}

const AdminProductImageList: React.FC<AdminProductImageListProps> = ({ products, onSave, onDelete }) => {
  const { featuredProductsState, addFeaturedProduct, removeFeaturedProduct } = useAdminContext();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [selectedImage, setSelectedImage] = React.useState<number | null>(null);
  const [openEditModal, setOpenEditModal] = React.useState<boolean>(false);
  const [openViewModal, setOpenViewModal] = React.useState<boolean>(false);
  const [selectedProduct, setSelectedProduct] = React.useState<ProductData | undefined>();

  const handleImageClick = (imageId: number) => {
    setSelectedImage(imageId === selectedImage ? null : imageId);
  };

  const handleEdit = (product: ProductData) => {
    setSelectedProduct(product);
    setOpenEditModal(true);
  };

  const handleDelete = (productData: ProductData) => {
    onDelete(productData);
  };

  const handleView = (product: ProductData) => {
    setOpenViewModal(true);
    setSelectedProduct(product);
  };

  const handleToggleFeatured = (product: ProductData) => {
    const updatedFeaturedProductIds = new Set(featuredProductsState.featuredProductIds);
    if (updatedFeaturedProductIds.has(product.id)) {
      removeFeaturedProduct(Number(product.id));
    } else {
      addFeaturedProduct(Number(product.id));
    }
  };

  return (
    <>
      <ImageList cols={isMobile ? 2 : 3} gap={2}>
        {products.map((item) => (
          <ImageListItem key={item.main_image.id} sx={{ width: '100%', position: 'relative' }}>
            <img
              onClick={() => handleImageClick(item.main_image.id)}
              src={`${item.main_image.data}`}
              alt={item.name}
              loading="lazy"
              className={`zoom-effect ${selectedImage === item.main_image.id ? 'selected' : ''}`}
              style={{
                width: '95%',
                height: isMobile ? 124 : 248,
                objectFit: 'cover',
                filter: selectedImage === item.main_image.id ? 'grayscale(100%)' : 'none',
                cursor: 'pointer'
              }}
            />
            <IconButton
              onClick={() => handleToggleFeatured(item)}
              color="secondary"
              aria-label="toggle featured"
              sx={{
                position: 'absolute',
                top: 10,
                right: 15,
                backgroundColor: '#ffffff',
                border: `1px solid ${theme.palette.primary.main}`,
                '&:hover': {
                  backgroundColor: 'rgba(211, 211, 211, 0.7)',
                  border: `2px solid ${theme.palette.primary.light}`,
                },
              }}
            >
              {featuredProductsState.featuredProductIds.has(item.id) ? (
                <Star fontSize={isMobile ? 'small' : 'medium'} sx={{ color: 'gold' }} />
              ) : (
                <StarBorder fontSize={isMobile ? 'small' : 'medium'} />
              )}
            </IconButton>
            {selectedImage === item.main_image.id && (
              <Stack direction="row" spacing={isMobile ? 0.5 : 2} sx={{ position: 'absolute', top: isMobile ? '20%' : '50%', left: isMobile ? '47.5%' : '50%', transform: 'translate(-50%, -50%)', zIndex: 5 }}>
                <IconButton
                  onClick={() => handleEdit(item)}
                  color="primary"
                  aria-label="edit"
                  sx={{
                    backgroundColor: '#ffffff',
                    border: `1px solid ${theme.palette.info.light}`,
                    '&:hover': {
                      backgroundColor: 'rgba(211, 211, 211, 0.7)',
                      border: '2px solid',
                    },
                  }}
                >
                  <Edit fontSize={isMobile ? 'small' : 'medium'} />
                </IconButton>
                <IconButton
                  onClick={() => handleDelete(item)}
                  color="error"
                  aria-label="delete"
                  sx={{
                    backgroundColor: '#ffffff',
                    border: `1px solid ${theme.palette.error.light}`,
                    '&:hover': {
                      backgroundColor: 'rgba(211, 211, 211, 0.7)',
                      border: '2px solid',
                    },
                  }}
                >
                  <Delete fontSize={isMobile ? 'small' : 'medium'} />
                </IconButton>
                <IconButton
                  onClick={() => handleView(item)}
                  color="inherit"
                  aria-label="view"
                  sx={{
                    backgroundColor: '#ffffff',
                    border: '1px solid',
                    '&:hover': {
                      backgroundColor: 'rgba(211, 211, 211, 0.7)',
                      border: '2px solid',
                    },
                  }}
                >
                  <Visibility fontSize={isMobile ? 'small' : 'medium'} />
                </IconButton>
              </Stack>
            )}
            <ImageListItemBar
              title={
                <Stack spacing={1} sx={{ maxWidth: "100%", mt: 1, mb: 0.5, whiteSpace: 'normal' }}>
                  <Typography
                    textAlign="center"
                    fontSize={isMobile ? "1.1rem" : "1.25rem"}  // Use a larger variant for larger screens
                    fontWeight="medium" // Use medium font weight for subtle emphasis
                    sx={{ wordBreak: 'break-word' }} // Ensures long words wrap properly
                  >
                    {item.name}
                  </Typography>
                </Stack>
              }
              subtitle={
                <Typography
                  textAlign="center"
                  fontStyle="italic"
                  variant="body2" // Slightly smaller font size for the price
                >
                  {item.price} €
                </Typography>
              }
              position="below"
            />
          </ImageListItem>
        ))}
      </ImageList>
      <ProductModal type="edit" open={openEditModal} onClose={() => { setOpenEditModal(false) }} onSave={onSave} product={selectedProduct} />
      <ProductViewModal open={openViewModal} product={selectedProduct!} onClose={() => { setOpenViewModal(false) }} />
    </>
  );
};

export default AdminProductImageList;
