import React, { useEffect, useRef, useState } from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import { useTheme } from '@mui/material/styles';
import AdminProductImageList from './AdminProductList';
import { Stack } from '@mui/system';
import { Box, Button, CircularProgress, TextField, IconButton, Paper } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import StarIcon from '@mui/icons-material/Star';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import ProductModal, { ProductModalRef } from './ProductEditModal';
import { ProductData, ProductForm, ProductInput, ProductUpdate } from '../../../../models/product';
import { ProductsState, useAdminContext } from '../AdminContext';
import apiConfigInstance from '../../../../api/apiConfig';
import ProductService from '../../../../api/productService';
import { AxiosError } from 'axios';
import ConfirmationModal from '../../../utils/modals/ConfirmationModal';
import { Category } from '../../../../models/category';
import { SentimentDissatisfiedOutlined } from '@mui/icons-material';
import { useError } from '../../../errors/ErrorContext';
import SuccessModal from '../../../utils/modals/SuccessModal';


const AdminShopPage = () => {
  const productModalRef = useRef<ProductModalRef>(null);

  const { categoriesState, productsLookup, featuredProductsState, appendProductByCategory, deleteProductByCategory, updateProductByCategory } = useAdminContext();
  const { handleError } = useError()

  const [selectedCategory, setSelectedCategory] = useState<Category | undefined>(
    categoriesState.categories.length > 0 ? categoriesState.categories[0] : undefined
  );

  const [products, setProducts] = useState<ProductsState>(() => {
    const initialProducts =
      categoriesState.categories.length > 0 && selectedCategory
        ? productsLookup.products.get(selectedCategory.id)
        : undefined;

    // Return either the looked up products or a default state
    return initialProducts ?? { loading: true, products: [], error: null };
  });

  const [filteredProducts, setFilteredProducts] = useState<ProductData[]>([]);
  const [openCreate, setOpenCreate] = useState<boolean>(false);
  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState<boolean>(false);
  const [productToDelete, setProductToDelete] = useState<ProductData | null>(null);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [showFeatured, setShowFeatured] = useState<boolean>(false);

  const [successModalData, setSuccessModalData] = useState<{ open: boolean, title: string, text: string }>({ open: false, title: "", text: "" });

  // Product API Service
  const productService = new ProductService(apiConfigInstance);
  const theme = useTheme();

  useEffect(() => {
    if (selectedCategory) {
      setProducts(productsLookup.products.get(selectedCategory.id) || { loading: false, products: [], error: null });
    } else if (categoriesState.categories.length === 0) {
      setProducts({ loading: false, products: [], error: null }); // Handle no categories scenario
    }
  }, [productsLookup, selectedCategory, categoriesState.categories.length]);

  useEffect(() => {
    if (products && !products.loading) {
      let filtered = products.products.filter(product =>
        product.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
        product.short_description.toLowerCase().includes(searchQuery.toLowerCase())
      );

      if (showFeatured) {
        filtered = filtered.filter(product => featuredProductsState.featuredProductIds.has(product.id));
      }

      setFilteredProducts(filtered);
    }
  }, [searchQuery, products, showFeatured, featuredProductsState]);

  const handleCategoryClick = (category: Category) => {
    setSelectedCategory(category);
    setProducts(productsLookup.products.get(category.id) || { loading: false, products: [], error: null });
    setSearchQuery(''); // Reset search query when category changes
    setShowFeatured(false); // Reset featured filter when category changes
  };

  const handleProductCreate = (productForm: ProductForm) => {
    const productInput: ProductInput = {
      id: productForm.id,
      name: productForm.name,
      short_description: productForm.short_description,
      main_description: productForm.main_description,
      price: productForm.price,
      sold: productForm.sold,
      category_id: productForm.category_id || 0,
      instagram_link: productForm.instagram_link,
      main_image_file: productForm.main_image_file ? productForm.main_image_file : null,
      secondary_images_files: productForm.secondary_images_files
    };

    productService.createProduct(productInput).then((product: ProductData) => {
      setSuccessModalData({ open: true, title: `Producto Registrado`, text: `Producto ${product.name} registrado correctamente.` });
      appendProductByCategory(product.category.id, product)
      resetProductForm();
    }).catch((error: AxiosError) => {
      handleError(error, true, "No se ha podido registrar el producto.");
    });
  };

  const handleDeleteModalOpen = (productData: ProductData) => {
    setProductToDelete(productData);
    setOpenDeleteConfirmation(true);
  };

  const resetProductForm = () => {
    if (productModalRef.current) {
      productModalRef.current.resetFormData();
    }
  };

  const handleProductDelete = (productData: ProductData) => {
    productService.deleteProduct(productData.id).then((deletedProduct) => {
      setSuccessModalData({ open: true, title: `Producto eliminado`, text: `Producto ${productData.name} eliminado correctamente.` });
      deleteProductByCategory(productData.category.id, productData.id);
    }).catch((error: AxiosError) => {
      console.log(error);
      handleError(error, true, "No se ha podido eliminar el producto.");
    });

    setOpenDeleteConfirmation(false);
  };

  const handleProductUpdate = (productForm: ProductForm) => {
    const productUpdate: ProductUpdate = {
      product_id: productForm.id,
      name: productForm.name,
      short_description: productForm.short_description,
      main_description: productForm.main_description,
      price: productForm.price,
      sold: productForm.sold,
      category_id: productForm.category_id || 0,
      instagram_link: productForm.instagram_link,
      main_image_id: productForm.main_image ? productForm.main_image.id : null,
      secondary_images_ids: productForm.secondary_images.map((secondaryImage) => secondaryImage.id),
      main_image_file: productForm.main_image_file ? productForm.main_image_file : null,
      secondary_images_files: productForm.secondary_images_files
    };

    productService.updateProduct(productUpdate).then((product: ProductData) => {
      setSuccessModalData({ open: true, title: `Producto actualizado.`, text: `El producto: ${productForm.name} ha sido actualizado.` });
      updateProductByCategory(product.category.id, product)
      resetProductForm()
    }).catch((error: AxiosError) => {
      console.log(error);
      handleError(error, true, "No se ha podido actualizar el producto.");
    });
  };

  const saveProduct = (type: "edit" | "create", productForm: ProductForm) => {
    if (type === "create") {
      handleProductCreate(productForm);
    } else {
      handleProductUpdate(productForm);
    }
  };

  return (
    <>
      <Paper sx={{ mt: 2, pt: 4, pb: 2, backgroundColor: "rgb(255,255,255,0.8)" }}>
        <Grid container display="flex" justifyContent="center" spacing={2} minWidth={"50vw"}>
          {/* Left side: Categories */}
          <Grid item xs={10} md={2}>
            <Typography variant="h5" gutterBottom>Categorías</Typography>
            {categoriesState.loading ? (
              <Stack sx={{ textAlign: 'center', alignItems: "center", mt: 2 }}>
                <CircularProgress />
                <Typography variant="body2" sx={{ mt: 2 }}>Cargando categorías...</Typography>
              </Stack>
            ) : categoriesState.error || !categoriesState.categories.length ? (
              <Stack sx={{ textAlign: 'center', alignItems: "center", mt: 2 }}>
                <SentimentDissatisfiedOutlined fontSize="inherit" style={{ fontSize: "2rem", color: theme.palette.warning.main }} />
                <Typography variant="body2" sx={{ mb: 2 }}>No se han encontrado categorías</Typography>
              </Stack>
            ) : (
              <List>
                {categoriesState.categories.map((category, idx) => (
                  <ListItemButton
                    key={category.id}
                    onClick={() => handleCategoryClick(category)}
                    sx={{
                      bgcolor: selectedCategory?.id === category.id ? theme.palette.grey[400] : 'transparent',
                      color: selectedCategory?.id === category.id ? theme.palette.primary.contrastText : 'inherit',
                      '&:hover': {
                        bgcolor: theme.palette.grey[300],
                      },
                    }}
                  >
                    <ListItemText primary={category.name} />
                  </ListItemButton>
                ))}
              </List>
            )}
          </Grid>

          {/* Right side: Products */}
          <Grid item xs={10} md={8}>
            <Stack direction={"row"} display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
              <Typography variant="h5" gutterBottom>{selectedCategory ? selectedCategory.name : "Productos"}</Typography>
              <Button variant='outlined' onClick={() => setOpenCreate(true)}>
                <Stack direction={"row"} spacing={1} alignItems={"center"}>
                  <Typography>Añadir</Typography>
                  <AddIcon />
                </Stack>
              </Button>
            </Stack>
            <Stack direction="row" spacing={2} alignItems="center" sx={{ my: 2 }}>
              <TextField
                variant="outlined"
                size="small"
                fullWidth
                placeholder="Buscar producto por nombre o descripción"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
              <IconButton onClick={() => setShowFeatured(!showFeatured)} color={showFeatured ? 'primary' : 'default'}>
                {showFeatured ? <StarIcon /> : <StarBorderIcon />}
              </IconButton>
            </Stack>
            {/* Conditional rendering based on whether there are products */}

            {products.loading ? (
              <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50vh' }}>
                <Stack spacing={1} alignItems={"center"}>
                  <Typography>
                    Cargando categorias...
                  </Typography>
                  <CircularProgress />
                </Stack>

              </Box>
            ) : products.error ? (
              <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50vh' }}>
                <Stack sx={{ textAlign: 'center', alignItems: "center", mt: -10 }}>
                  <SentimentDissatisfiedOutlined fontSize="inherit" style={{ fontSize: "5rem", color: theme.palette.warning.main }} />
                  <Typography variant="h6" sx={{ mb: 2 }}>No se han podido cargar los productos</Typography>
                </Stack>
              </Box>
            ) : filteredProducts.length > 0 ? (
              <AdminProductImageList
                products={filteredProducts}
                onSave={(type, productInput) => { saveProduct(type, productInput); }}
                onDelete={(productData) => { handleDeleteModalOpen(productData); }}
              />
            ) : (
              <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50vh' }}>
                <Stack sx={{ textAlign: 'center', alignItems: "center", mt: -10 }}>
                  <SentimentDissatisfiedOutlined fontSize="inherit" style={{ fontSize: "5rem", color: theme.palette.warning.main }} />
                  <Typography variant="h6" sx={{ mb: 2 }}>No se han encontrado productos</Typography>
                </Stack>
              </Box>

            )}
          </Grid>
        </Grid >
        <ProductModal
          ref={productModalRef} // Pass the ref here
          type='create'
          open={openCreate}
          onClose={() => setOpenCreate(false)}
          onSave={(type, productInput) => saveProduct(type, productInput)}
        />
        <ConfirmationModal open={openDeleteConfirmation} onClose={() => {
          setOpenDeleteConfirmation(false);
          setProductToDelete(null);
        }} onConfirm={() => {
          if (productToDelete !== null) {
            handleProductDelete(productToDelete);
          }
        }} title={
          `Eliminar producto: ${productToDelete?.name}`} content={'Seguro que quieres eliminar el producto?'} confirmText={'Eliminar'} cancelText={'Cancelar'} />
      </Paper>
      <SuccessModal open={successModalData.open} onClose={() => setSuccessModalData({
        open: false, title: "", text: ""
      })} title={successModalData.title} content={successModalData.text}></SuccessModal>
    </>
  );
}

export default AdminShopPage;
